<template>
    <div class="product_lay_out">
        <div class="top_advBox">
        <TopAdv />
        </div>
        <ProductPage />
        <BottomIntroduce />
    </div>
</template>
<script>
import TopAdv from '../../components/TopAdv'
import BottomIntroduce from '../../components/BottomIntroduce'

import ProductPage from './components/ProductPage'
export default {
    name:'ProductLayout',
    components:{
        TopAdv,
        ProductPage,
        BottomIntroduce
    },
    created () {
        // 修改顶部导航当前选中
        this.$store.commit('modifyVuexAdvActive',{vuexAdvActive:1})
    }
}
</script>
<style scoped lang="less">
.product_lay_out{
    min-width: 1200px;
}
</style>